let resumeData = {
  "imagebaseurl": "https://images.app.goo.gl/SJvxvHUK995mDFuS8",
  "name": "Shamil Asitha Kuruppu",
  "role": "Frontend Developer and Data Scientist",
  "linkedinId": "Your LinkedIn Id",
  "skypeid": "Your skypeid",
  "roleDescription": "I like dabbling in various parts of frontend development and like to learn about new technologies, travel or simply play games in my free time.",
  "socialLinks": [
    {
      "name": "linkedin",
      "url": "https://www.linkedin.com/in/kuruppu/",
      "className": "fa fa-linkedin"
    },
    {
      "name": "github",
      "url": "https://github.com/asithakuruppu",
      "className": "fa fa-github"
    }
    // {
    //   "name": "skype",
    //   "url": "http://twitter.com/rbhatia46",
    //   "className": "fa fa-twitter"
    // }
  ],
  "aboutme": "I am a Big Data Engineer and a ",
  "address": "Singapore",
  "website": "https://www.linkedin.com/in/kuruppu/",
  "education": [
    {
      "UniversityName": "Robert Gorden University",
      "specialization": "M.Sc. Big Data Analytics",
      "MonthOfPassing": "Aug",
      "YearOfPassing": "2023",
      "Achievements": ""
    },
    {
      "UniversityName": "University of Colombo School of Computing",
      "specialization": "B.Sc. in Computer Science",
      "MonthOfPassing": "Sep",
      "YearOfPassing": "2017"
    }
  ],
  "work": [
    {
      "CompanyName": "Singapore Management University",
      "specialization": "Research Engineer",
      "MonthOfLeaving": "Oct",
      "YearOfLeaving": "2023",
      // "Achievements": "Some Achievements"
    },
    {
      "CompanyName": "Mobitel PVT LTD",
      "specialization": "Senior Software Engineer",
      "MonthOfLeaving": "Jun",
      "YearOfLeaving": "2022",
      // "Achievements": "Some Achievements"
    },
    {
      "CompanyName": "Thinkcube Systems/ Emageia",
      "specialization": "Software Engineer",
      "MonthOfLeaving": "Jan",
      "YearOfLeaving": "2018",
      // "Achievements": "Some Achievements"
    }
  ],
  "skillsDescription": "Your skills here",
  "skills": [
    {
      "skillname": "ReactNative"
    },
    {
      "skillname": "React"
    },
    {
      "skillname": "NodeJS",
      "percentage": "80"
    },
    {
      "skillname": "Pandas",
      "percentage": "80"
    }
  ],
  "portfolio": [
    {
      "name": "eGN",
      "description": "mobileapp",
      "imgurl": "images/portfolio/phone.jpg"
    },
    {
      "name": "Childcare Analytics",
      "description": "WebApp",
      "imgurl": "images/portfolio/project.jpg"
    }
  ],
  // "testimonials": [
  //   {
  //     "description": "This is a sample testimonial",
  //     "name": "Some technical guy"
  //   },
  //   {
  //     "description": "This is a sample testimonial",
  //     "name": "Some technical guy"
  //   }
  // ]
}

export default resumeData